<template>
  <v-main>
    <v-container fluid>
      <BaseBreadcrumb admin />
      <div class="d-flex flex-wrap">
        <v-btn
          v-for="btn in emailButtons"
          :key="btn.to"
          text
          exact
          :to="btn.to"
          class="text-capitalize mx-1"
          color="primary"
        >
          {{ btn.text }}
          <v-icon v-if="btn.icon">
            {{ btn.icon }}
          </v-icon>
        </v-btn>
      </div>
      <div class="mt-2">
        <router-view></router-view>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {
  components: {
    BaseBreadcrumb: () => import('../../../components/Core/Base/BreadCrumb.vue')
  },
  data() {
    return {
      emailButtons: [
        { text: 'Send Email', to: '/admin/email', icon: 'mdi-email' },
        {
          text: 'Mailing List',
          to: '/admin/email/mailing-list',
          icon: 'mdi-account-multiple'
        },
        {
          text: 'Email HTML',
          to: '/admin/email/html',
          icon: 'mdi-language-html5'
        },
        {
          text: 'History',
          to: '/admin/email/history',
          icon: 'mdi-history'
        }
      ]
    };
  }
};
</script>

<style>
</style>
